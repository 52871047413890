var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',[_c('AppOverAllAssessmentListingFilterHeader',{on:{"filter_change":_vm.process_assessment_listing}})],1),(_vm.loading)?[_c('v-row',{staticClass:"base-card pa-0 data-row my-5 my-md-0",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-3 px-md-4 py-md-5",attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1)],1)]:[_c('div',{staticClass:"mt-3"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"items":_vm.overall_assessment_details.results,"page":_vm.overall_assessment_details.page,"items-per-page":_vm.overall_assessment_details.limit,"hide-default-footer":"","mobile-breakpoint":"100"},on:{"update:page":function($event){return _vm.$set(_vm.overall_assessment_details, "page", $event)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.headers),function(header,index){return _c('th',{key:index},[_c('div',{staticClass:"d-flex align-start flex-column"},[_c('p',{staticClass:"mb-0 base-section-sub-title font-weight-bold"},[_vm._v(" "+_vm._s(header.text)+" ")]),(header.sub_text)?_c('p',{staticClass:"mb-0 base-body-text text--grey"},[_vm._v(" "+_vm._s(header.sub_text)+" ")]):_vm._e()])])}),0)])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',[_c('td',[_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(index + 1)+" ")])]),_c('td',[_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(_vm.format_date(item.date))+" ")])]),_c('td',[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.open_user_details(item.user, item.status)}}},[_c('p',{staticClass:"\n                    mb-0\n                    base-section-sub-title\n                    font-weight-bold\n                    text-capitalize\n                  "},[_vm._v(" "+_vm._s(item.user.first_name)+" "+_vm._s(item.user.last_name)+" ")])])]),_c('td',[_c('div',{staticClass:"d-flex align-center"},[(item.english_assessment)?_c('p',{class:("mb-0 base-body-text font-weight-bold " + (_vm.get_color_for_percentage(
                    item.english_assessment.overall_score
                  )))},[_vm._v(" "+_vm._s(item.english_assessment.overall_score)+"% ")]):_c('p',{staticClass:"mb-0 base-body-text font-weight-bold danger--text"},[_vm._v(" N/A ")]),(
                    item.english_assessment &&
                    item.english_assessment.overall_score
                  )?_c('v-chip',{staticClass:"ml-md-2 ml-1",class:_vm.get_pass_or_fail_class(
                      item.english_assessment.overall_score
                    )},[_vm._v(" "+_vm._s(_vm.assessment_pass_fail_result( item.english_assessment.overall_score ) ? _vm.$t("assessments.pass") : _vm.$t("assessments.fail"))+" ")]):_vm._e(),(item.english_assessment)?_c('div',{staticClass:"action-buttons ml-1"},[_c('v-btn',{staticClass:"btn view_btn",attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.view_assessment_result(
                        item.english_assessment_id,
                        item.user.id,
                        'english',
                        item.version
                      )}}},[_c('inline-svg',{attrs:{"width":"25","height":"25","src":require('@/assets/icons/linear/eye.svg'),"transformSource":_vm.transform_view_icon}})],1)],1):_vm._e(),(item.english_assessment)?_c('v-btn',{staticClass:"btn delete_btn ml-2",attrs:{"icon":"","color":"danger"},on:{"click":function($event){return _vm.delete_assessment(item, true)}}},[_c('inline-svg',{attrs:{"width":"21","height":"21","src":_vm.delete_icon,"transformSource":_vm.transform_trash_icon}})],1):_vm._e()],1)]),_c('td',[_c('div',{staticClass:"d-flex align-center"},[(item.annotation_assessment)?_c('p',{class:("mb-0 base-body-text font-weight-bold " + (_vm.get_color_for_percentage(
                    _vm.get_percentage(item.annotation_assessment)
                  )))},[_vm._v(" "+_vm._s(_vm.get_percentage(item.annotation_assessment))+"% ")]):_c('p',{staticClass:"mb-0 base-body-text font-weight-bold danger--text"},[_vm._v(" N/A ")]),(_vm.get_percentage(item.annotation_assessment))?_c('v-chip',{staticClass:"ml-md-2 ml-1",class:_vm.get_pass_or_fail_class(
                      _vm.get_percentage(item.annotation_assessment)
                    )},[_vm._v(" "+_vm._s(_vm.assessment_pass_fail_result( _vm.get_percentage(item.annotation_assessment) ) ? _vm.$t("assessments.pass") : _vm.$t("assessments.fail"))+" ")]):_vm._e(),(item.annotation_assessment)?_c('div',{staticClass:"action-buttons ml-1"},[_c('v-btn',{staticClass:"btn view_btn",attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.view_assessment_result(
                        item.annotation_assessment_id,
                        item.user.id,
                        'annotation',
                        item.version
                      )}}},[_c('inline-svg',{attrs:{"width":"25","height":"25","src":require('@/assets/icons/linear/eye.svg'),"transformSource":_vm.transform_view_icon}})],1)],1):_vm._e(),(item.annotation_assessment)?_c('v-btn',{staticClass:"btn delete_btn ml-2",attrs:{"icon":"","color":"danger"},on:{"click":function($event){return _vm.delete_assessment(item, false)}}},[_c('inline-svg',{attrs:{"width":"21","height":"21","src":_vm.delete_icon,"transformSource":_vm.transform_trash_icon}})],1):_vm._e()],1)]),_c('td',[_c('v-chip',{staticClass:"assessment-version"},[_vm._v(" "+_vm._s(_vm.get_assessment_version(item.version))+" ")])],1),_c('td',[_c('div',{staticClass:"d-flex align-center"},[(item.average_score)?_c('p',{class:("mb-0 base-body-text font-weight-bold " + (_vm.get_color_for_percentage(
                    item.average_score
                  )))},[_vm._v(" "+_vm._s(item.average_score)+"% ")]):_c('p',{staticClass:"mb-0 base-body-text font-weight-bold danger--text"},[_vm._v(" N/A ")]),(item.average_score)?_c('v-chip',{staticClass:"ml-md-2 ml-1",class:_vm.get_pass_or_fail_class(item.average_score)},[_vm._v(" "+_vm._s(_vm.assessment_pass_fail_result(item.average_score) ? _vm.$t("assessments.pass") : _vm.$t("assessments.fail"))+" ")]):_vm._e()],1)])])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"t-footer"},[_c('v-row',{staticClass:"mt-4",attrs:{"align":"center"}},[(_vm.loading)?[_c('v-skeleton-loader',{staticClass:"d-flex align-center mx-4 pagination-loader",attrs:{"tile":true,"type":"avatar@4"}})]:[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12","md":"10"}},[_c('v-pagination',{staticClass:"pagination-wrapper",attrs:{"color":"primary","length":Math.ceil(
                        _vm.overall_assessment_details.total /
                          _vm.overall_assessment_details.limit
                      ),"disabled":_vm.overall_assessment_details.total <
                      _vm.overall_assessment_details.limit,"total-visible":10,"prev-icon":_vm.get_site_direction === _vm.SiteDirections.LTR
                        ? 'mdi-arrow-left'
                        : 'mdi-arrow-right',"next-icon":_vm.get_site_direction === _vm.SiteDirections.LTR
                        ? 'mdi-arrow-right'
                        : 'mdi-arrow-left'},on:{"input":_vm.process_assessment_listing},model:{value:(_vm.overall_assessment_details.page),callback:function ($$v) {_vm.$set(_vm.overall_assessment_details, "page", $$v)},expression:"overall_assessment_details.page"}}),(
                      _vm.overall_assessment_details.total >
                      _vm.overall_assessment_details.limit
                    )?_c('v-select',{staticClass:"base-card select-box",attrs:{"flat":"","hide-details":true,"items":_vm.per_page_options,"single-line":"","label":"Sort by","color":"primary","solo":"","attach":true},on:{"change":_vm.reprocess_limit},model:{value:(_vm.overall_assessment_details.limit),callback:function ($$v) {_vm.$set(_vm.overall_assessment_details, "limit", $$v)},expression:"overall_assessment_details.limit"}}):_vm._e()],1),_c('v-col',{staticClass:"d-flex align-center flex-column",staticStyle:{"gap":"0.8rem"},attrs:{"cols":"2"}},[_c('RefreshButton',{staticClass:"ml-auto",on:{"refresh_listing":_vm.process_assessment_listing}}),(
                      _vm.overall_assessment_details.results &&
                      _vm.overall_assessment_details.results.length > 0
                    )?_c('v-btn',{staticClass:"\n                      download-btn\n                      text-capitalize\n                      ml-auto\n                      base-body-text\n                      font-weight-bold\n                    ",attrs:{"loading":_vm.download_csv_loading},on:{"click":_vm.export_csv}},[_vm._v(" Export CSV "),_c('inline-svg',{attrs:{"src":require('@/assets/icons/linear/import.svg')}})],1):_vm._e()],1)]],2)],1)]},proxy:true}])})],1)],_c('v-dialog',{attrs:{"max-width":"700px","transition":"dialog-top-transition"},model:{value:(_vm.is_delete),callback:function ($$v) {_vm.is_delete=$$v},expression:"is_delete"}},[_c('DeleteItem',{attrs:{"title":(" " + (_vm.$t('assessments.assessment-delete-msg'))),"sub_title":(" " + (_vm.$t('assessments.irreverisble'))),"submit_btn_text":(" " + (_vm.$t('assessments.reset-assessment'))),"del_icon":_vm.delete_icon,"transform_icon_type":"stroke"},on:{"cancel":function($event){_vm.is_delete = false},"submit":_vm.dialog_action}})],1),(_vm.on_boarding_invitation)?_c('OnBoardingInvitationCard',{attrs:{"user":_vm.user_object,"status":_vm.status},on:{"close":function($event){_vm.on_boarding_invitation = false}}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }